@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Russo+One&family=Work+Sans:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&family=Plus+Jakarta+Sans:ital,wght@1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');

/* .ant-typography {
  font-family: 'Inter', sans-serif !important;
}
* {
  font-family: 'Inter', sans-serif !important;
}

.inter {
  font-family: 'Inter', sans-serif !important;
}

.plusJakartaSans {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.titlePage {
  font-family: 'plus jakarta sans', sans-serif !important;
  text-transform: uppercase;
} */

#webpack-dev-server-client-overlay {
    display: none !important;
}
a {
    color: #044737 !important;
    font-weight: 600 !important;
}

.russoOne {
    font-family: 'Russo One', sans-serif !important;
}

body {
    background-color: white;
    /* overflow-y: hidden;
  overflow: hidden; */
}

.ant-layout {
    background-color: white;
}

.primary {
    color: #8666ff !important;
}

.secondary {
    color: #989bab !important;
}

.d-flex {
    display: flex !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.d-center {
    align-items: center;
    justify-content: center;
}

.purpleGradient {
    background-color: #8ec5fc;
    background-image: linear-gradient(30deg, #626aed 10%, #a87cf4 100%);
    border-radius: 15px;
    padding: 10px;
}

.titleSidebar {
    color: #bcc0d3;
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-left: 10px;
}

.menu {
    display: flex;
    width: 225px;
    height: 90vh;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
}

.line-container {
    display: flex;
    align-items: stretch;
    position: relative;
    width: 1px;
    height: 100%;
    overflow: hidden;
}

.horizontal-line {
    border: none;
    height: 1px;
    background: #d8dae5;
    margin: 0;
    width: 100%;
}

.custom-modal {
    max-width: 800px;
    margin: 0 auto;
    max-height: 400px;
}

.info-container {
    text-align: center;
    padding: 20px;
}

.custom-card {
    border: 1px solid #e9e9e9;
    width: 100%;
    border-radius: 10px;
    /* margin: 7px 0px; */
    margin-top: 10px;

    transition: box-shadow 0.3s;
}

.custom-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-content {
    padding: 15px 20px;
    min-height: 140px;
}

.icon-container {
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.2);

    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.card-title {
    flex-grow: 1;
}

.card-description {
    margin-top: 10px;
    color: #888;
}

.integration-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 300px;
    height: 100px;
}

.integrationModal .ant-modal-content {
    padding: 0;
}

.hover-rectangular:hover {
    background-color: #eae5fe;
    color: #8666ff;
    border-radius: 0;
    transition: background-color 0.3s;
}

.teamRowSelector {
    padding: 3px 5px;
    border-radius: 5px;
}

.teamRowSelector:hover {
    background-color: #efebfe;
    color: #8666ff;
    border-radius: 5px;
    transition: background-color 0.3s;
}

/* width */
/* ::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.clickableIcon:hover {
    color: #9375ff;
    cursor: pointer;
}

.clickableIconDelete:hover {
    color: #ff4d4f;
}

.iconColor {
    color: #50566b;
}

.headlines {
    color: #101840 !important;
}

svg {
    vertical-align: baseline !important;
}

button,
span {
    display: inline-flex !important;
    align-items: center !important;
}

/* button {
  display: flex !important;
} */

.ant-typography .ant-typography-copy {
    padding-bottom: 10px !important;
}

.ant-typography-copy .ant-typography-copy-success {
    padding-bottom: -10px !important;
}

.clickable {
    cursor: pointer;
}

.clickable-link:hover {
    color: #000000;
    text-decoration: underline;
}

#HW_badge_cont {
    height: 20px;
}

/* v2 */

/* TABS */
.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--neutral-1);

    /* Paragraph/Medium Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}

.ant-tabs-tab .ant-tabs-tab-btn {
    color: #474d66;

    /* Paragraph/Medium Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

/* BUTTONS */

.ant-btn {
    font-family: Inter !important;
    font-weight: 600 !important;
    text-align: center !important;
    justify-content: center !important;
}

.activeBtn {
    color: var(--primary-1);
    border: 1px solid var(--primary-1);
}

/* TABLE */

td .ant-table-cell {
    /*align center*/
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
}

.ant-table-thead .ant-table-cell {
    /* Headings/Table */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 1px;
}

.icon {
    color: var(--neutral-1) !important;
}

.icon:hover {
    color: var(--neutral-1) !important;
}

.iconDanger:hover {
    color: #d14343 !important;
}

/* MENU */
.ant-menu-item svg {
    color: var(--neutral-2) !important;
}

.ant-menu-item-selected {
    font-weight: 600 !important;
}

.ant-menu-item-selected svg {
    color: var(--neutral-2) !important;
}

/* INPUTS */

.ant-select-selector {
    min-height: 36px !important;
}

.ant-input-number .ant-input-number-input {
    padding: 6px 11px;
}

.ant-select-selector {
    min-height: 36px !important;
}

.ant-form-item {
    margin-bottom: 5px !important;
}

/*TO THIS CLASS labelrequired ADD A RED * BEFORE THE TEXT */
.labelrequired:before {
    content: '*';
    color: #ff4d4f;
    font-size: 14px;
    margin-right: 4px;
}

/* CALENDAR */
.calendarselectionitem {
    cursor: pointer;
}

.calendarselectionitem:hover {
    background-color: #8666ff !important;
    color: white !important;
}

.calendarselecteditem {
    background-color: #8666ff !important;
    color: white !important;
}

/* MODAL */
.ant-modal-footer {
    display: flex;
    justify-content: flex-end;
}

.instant-search--algolia-search-hits-list {
    list-style: none;
    margin: 0px;
    padding: 0px;
    max-height: 400px;
    overflow-y: scroll;
}

.hit-details {
    display: flex;
    align-items: center;
}

.detail {
    margin-right: 10px;
}

.hit-card:hover {
    background-color: #eae5fe;
    cursor: pointer;
}

/* Agrega estilos de hover para la tarjeta */
.card-hover:hover {
    background-color: 'red';
    /* Cambia el color de fondo en hover */
    transition: background-color 0.3s ease;
}

.searchResultsContainer {
    display: block;
    background-color: #fafafa;
    position: absolute;
    z-index: 999999;
    margin-top: -5px;
    border: 1px solid #cecece;
    border-radius: 4px;
    max-height: 70vh;
    width: 100%;
    transform: translateX(-50%);
    left: 50%;
    box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
}

.main-search--input {
    width: fit-content;
}

.main-search--container {
    width: 90%;
    padding-left: 200px;
}

.header-icons--container {
    min-width: 275px;
}

@media (max-width: 990px) {
    .searchResultsContainer {
        width: 150%;
        min-width: 400px;
        left: 0%;
        transform: translateX(0%);
    }
}

/* Media queries */
@media (max-width: 750px) {
    .searchResultsContainer {
        width: 140%;
        min-width: 400px;
        left: 0%;
        transform: translateX(0%);
    }
}

@media (max-width: 480px) {
    .searchResultsContainer {
        width: 300px;
        left: 0%;
        min-width: 400px;
        transform: translateX(0%);
    }
}

@media screen and (max-width: 750px) {
    .main-search--input {
        width: 456px;
    }

    .header-help--text {
        display: none !important;
    }

    .main-search--container {
        padding-left: 60px;
    }

    .header-icons--container {
        min-width: 170px;
    }
}

@media screen and (max-width: 400px) {
    .main-search--input {
        width: 200px;
    }

    .header-help--text {
        display: none !important;
    }

    .main-search--container {
        padding-left: 60px;
    }
}

.testmode-modal--banner {
    position: absolute;
    height: 5px;
    background-color: #8666ff !important;
    top: 0;
    left: 0;
    width: 100%;
}

.testmode-modal--text {
    position: absolute;
    font-size: 10px;
    font-weight: 600;
    height: 20px;
    top: -5px;
    padding: 0px 10px;
    color: white;
    border-radius: 3px;
    background-color: #8666ff;
}

/*
.ant-drawer {
  z-index: 996 !important;
}

.ant-modal {
  z-index: 997 !important;
}

.relevant-modal {
  z-index: 999 !important;
} */

.clients-jtbd--card {
    background-color: #f8fafc;
    padding-bottom: 20px;
}

/*
#webpack-dev-server-client-overlay {
  display: none !important;
} */

.vertical-checkbox-group {
    display: flex;
    flex-direction: column;
}

.vertical-checkbox-group .ant-checkbox-wrapper {
    margin-left: 0;
    margin-bottom: 8px;
}

.pricing-card-deck {
    padding: 48px 24px;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
}

.pricing-cards {
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    padding-bottom: 20px;
}

.pricing-cards::-webkit-scrollbar {
    display: none;
}

.pricing-cards .ant-col {
    min-width: 300px;
}

@media (min-width: 1400px) {
    .pricing-card-deck {
        position: relative;
    }

    .pricing-cards {
        margin: 0;
        justify-content: center;
    }
}

.confirm-onboarding--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 2rem; */
    width: 100%;
    height: auto;
    min-height: 100vh;
    /* max-height: 100vh; */
}
.onb-container--green {
    background: linear-gradient(221.51deg, #000000 0.75%, var(--primary-1) 69.64%);
}

/* Pricing Cards Styles */
.pricing-card {
    transition: all 0.3s ease;
    border: 1px solid var(--neutral-4);
}

.pricing-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12) !important;
}

.recommended-card {
    border: 2px solid var(--primary-1);
    background: linear-gradient(180deg, rgba(var(--primary-1-rgb), 0.02) 0%, rgba(var(--primary-1-rgb), 0) 100%);
}

.pricing-card .ant-typography {
    transition: color 0.3s ease;
}

.pricing-card:hover .ant-typography {
    color: var(--primary-1);
}

.pricing-card .ant-space {
    width: 100%;
}

.pricing-card .feature-icon {
    transition: color 0.3s ease;
}

.pricing-card:hover .feature-icon {
    color: var(--primary-1) !important;
}

.btn-pricing-plans {
    background: var(--primary-1) !important;
    color: white !important;
    border: none !important;
    height: 48px !important;
    font-weight: 600 !important;
    transition: all 0.3s ease !important;
}

.btn-pricing-plans:hover {
    background: var(--primary-2) !important;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
