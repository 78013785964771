label {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    position: relative !important;
    color: var(--neutral-1) !important;
}

a:hover {
    text-decoration: underline !important;
}

.ant-btn-primary:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
}

.ant-form-item-explain {
    font-family: 'Inter' !important;
    font-size: 12px !important;
    color: var(--neutral-3) !important;
}

#primaryColor {
    width: 100% !important;
    justify-content: space-between;
}

#secondaryColor {
    width: 100% !important;
    justify-content: space-between;
}

.bg-dots {
    background-image: radial-gradient(circle, #d0d0d0 1px, rgba(255, 255, 255, 0) 1px);
    background-size: 20px 20px;
}

.pricing-card .ant-card-body {
    padding: 10px !important;
}

.onboarding-steps .ant-steps-item-container {
    display: flex !important;
    align-items: center !important;
}

.colorOverride .ant-color-picker-trigger {
    align-items: center !important;
    justify-content: space-between !important;
    align-content: center !important;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: var(--neutral-1) !important;
    color: white !important;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell:hover .ant-picker-time-panel-cell-inner {
    background: var(--neutral-5) !important;
}
